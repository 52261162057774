import React from 'react';
import { useTranslation } from 'yoshi-flow-editor-runtime';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';

import { st, classes } from './Widget.st.css';
import { PlansList } from './PlanList';
import { EmptyState } from './EmptyState';
import { Popup } from './Popup';
import { TabState, PopupEnum, MessageCode } from '../../../types/common';
import { useSettings } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import settingsParams from '../settingsParams';

export interface ListProps {
  plans: PublicPlan[];
  tabState: TabState;
  isMobile?: boolean;
  popup: PopupEnum | null;
  message?: MessageCode;
  navigateToCheckout(plan: PublicPlan): void;
  hidePopup(): void;
}

export const List: React.FC<ListProps> = ({
  plans,
  tabState,
  navigateToCheckout,
  isMobile,
  popup,
  hidePopup,
  message,
}) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const showPageTitle = settings.get(settingsParams.showPageTitle);
  const showDisclaimer = settings.get(settingsParams.showDisclaimer);
  const textAlignment = settings.get(settingsParams.textAlignment);

  return (
    <div className={st(classes.root, { mobile: !!isMobile, textAlignment })} data-hook="PackagePicker-wrapper">
      {plans.length ? (
        <>
          <div className={classes.header}>
            {showPageTitle && <h1 data-hook="app-title">{settings.get(settingsParams.pageHeaderText)}</h1>}
          </div>
          {message ? <div data-hook="toast">{t('pp.checkout-missing-plan')}</div> : null}
          <PlansList
            isMobile={isMobile}
            navigateToCheckout={navigateToCheckout}
            plans={plans}
            showDemoHighlight={tabState === TabState.HIGHLIGHTED}
          />
          {showDisclaimer && (
            <div className={classes.disclaimer}>
              <p>{settings.get(settingsParams.disclaimerText)}</p>
            </div>
          )}
        </>
      ) : (
        <EmptyState />
      )}
      <Popup popup={popup} hidePopup={hidePopup} />
    </div>
  );
};
