import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
  IWixStyleFont,
} from 'yoshi-flow-editor-runtime/tpa-settings';

export type IComponentStyles = {
  backgroundColor: StyleParamType.Color;
  titleBackgroundColor: StyleParamType.Color;
  titleBackgroundColorHighlighted: StyleParamType.Color;
  planBenefitBackgroundColor: StyleParamType.Color;
  planBenefitBackgroundColorHighlighted: StyleParamType.Color;
  cardBorderWidth: StyleParamType.Number;
  cardBorderColor: StyleParamType.Color;
  cardBorderColorHighlighted: StyleParamType.Color;
  dividerWidth: StyleParamType.Number;
  dividerWidthHighlighted: StyleParamType.Number;
  dividerColor: StyleParamType.Color;
  dividerColorHighlighted: StyleParamType.Color;
  pageHeaderFont: StyleParamType.Font;
  pageHeaderColor: StyleParamType.Color;
  benefitsSpacing: StyleParamType.Number;
  horizontalPadding: StyleParamType.Number;
  verticalPadding: StyleParamType.Number;
  planTitleFont: StyleParamType.Font;
  planTitleColor: StyleParamType.Color;
  planPriceFont: StyleParamType.Font;
  planPriceColor: StyleParamType.Color;
  planTaglineFont: StyleParamType.Font;
  planTaglineColor: StyleParamType.Color;
  planBenefitsFont: StyleParamType.Font;
  planBenefitsColor: StyleParamType.Color;
  planRecurringOptionsFont: StyleParamType.Font;
  planRecurringOptionsColor: StyleParamType.Color;
  planDurationFont: StyleParamType.Font;
  planDurationColor: StyleParamType.Color;
  ribbonBackgroundColor: StyleParamType.Color;
  ribbonFont: StyleParamType.Font;
  ribbonColor: StyleParamType.Color;
  ribbonBorderWidth: StyleParamType.Number;
  ribbonBorderColor: StyleParamType.Color;
  planTitleColorHighlighted: StyleParamType.Color;
  planPriceColorHighlighted: StyleParamType.Color;
  planRecurringOptionsColorHighlighted: StyleParamType.Color;
  planTaglineColorHighlighted: StyleParamType.Color;
  planDurationColorHighlighted: StyleParamType.Color;
  planBenefitsColorHighlighted: StyleParamType.Color;
  buttonFont: StyleParamType.Font;
  buttonFontColor: StyleParamType.Color;
  buttonCornerRadius: StyleParamType.Number;
  buttonBorderWidth: StyleParamType.Number;
  buttonBorderColor: StyleParamType.Color;
  buttonColor: StyleParamType.Color;
  buttonFontColorHighlighted: StyleParamType.Color;
  buttonCornerRadiusHighlighted: StyleParamType.Number;
  buttonBorderColorHighlighted: StyleParamType.Color;
  buttonColorHighlighted: StyleParamType.Color;

  buttonEmptyCorneredFontStyle: StyleParamType.Font;
  buttonEmptyCorneredFontColor: StyleParamType.Color;
  buttonEmptyCorneredBorderWidth: StyleParamType.Number;
  buttonEmptyCorneredCornerRadius: StyleParamType.Number;
  buttonEmptyCorneredBorderOpacityAndColor: StyleParamType.Color;

  buttonEmptyRoundedFontStyle: StyleParamType.Font;
  buttonEmptyRoundedFontColor: StyleParamType.Color;
  buttonEmptyRoundedBorderWidth: StyleParamType.Number;
  buttonEmptyRoundedCornerRadius: StyleParamType.Number;
  buttonEmptyRoundedOpacityAndColor: StyleParamType.Color;

  buttonFullCorneredFontStyle: StyleParamType.Font;
  buttonFullCorneredFontColor: StyleParamType.Color;
  buttonFullCorneredCornerRadius: StyleParamType.Number;
  buttonFullCorneredBgColor: StyleParamType.Color;

  buttonFullRoundedFontStyle: StyleParamType.Font;
  buttonFullRoundedFontColor: StyleParamType.Color;
  buttonFullRoundedCornerRadius: StyleParamType.Number;
  buttonFullRoundedBgColor: StyleParamType.Color;

  showPageTitle: StyleParamType.Boolean;
};

export default createStylesParams<IComponentStyles>({
  backgroundColor: {
    type: StyleParamType.Color,
    key: 'app-bg-color',
    getDefaultValue: wixColorParam('color-1'),
  },
  titleBackgroundColor: {
    type: StyleParamType.Color,
    key: 'plan-top-bg-color',
    getDefaultValue: wixColorParam('color-1', 0),
  },
  titleBackgroundColorHighlighted: {
    type: StyleParamType.Color,
    key: 'plan-top-bg-color-highlighted',
    getDefaultValue: wixColorParam('color-5', 0.1),
  },
  planBenefitBackgroundColor: {
    type: StyleParamType.Color,
    key: 'plan-benefits-bg-color',
    getDefaultValue: wixColorParam('color-1', 0),
  },
  planBenefitBackgroundColorHighlighted: {
    type: StyleParamType.Color,
    key: 'plan-benefits-bg-color-highlighted',
    getDefaultValue: wixColorParam('color-1', 0),
  },
  cardBorderWidth: {
    type: StyleParamType.Number,
    key: 'card-border-width',
    getDefaultValue: () => 1,
  },
  cardBorderColor: {
    type: StyleParamType.Color,
    key: 'card-border-color',
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  cardBorderColorHighlighted: {
    type: StyleParamType.Color,
    key: 'card-border-color-highlighted',
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  dividerWidth: {
    type: StyleParamType.Number,
    key: 'divider-width',
    getDefaultValue: () => 1,
  },
  dividerWidthHighlighted: {
    type: StyleParamType.Number,
    key: 'divider-width-highlighted',
    getDefaultValue: () => 1,
  },
  dividerColor: {
    type: StyleParamType.Color,
    key: 'divider-color',
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  dividerColorHighlighted: {
    type: StyleParamType.Color,
    key: 'divider-color-highlighted',
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  pageHeaderFont: {
    type: StyleParamType.Font,
    key: 'page-header-font',
    getDefaultValue: wixFontParam('Page-title'),
  },
  pageHeaderColor: {
    type: StyleParamType.Color,
    key: 'page-header-color',
    getDefaultValue: wixColorParam('color-5'),
  },
  benefitsSpacing: {
    type: StyleParamType.Number,
    key: 'benefits-spacing',
    getDefaultValue: () => 1.5,
  },
  horizontalPadding: {
    type: StyleParamType.Number,
    key: 'horizontal-padding',
    getDefaultValue: () => 30,
  },
  verticalPadding: {
    type: StyleParamType.Number,
    key: 'vertical-padding',
    getDefaultValue: () => 30,
  },
  planTitleFont: {
    type: StyleParamType.Font,
    key: 'plan-title-font',
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  planTitleColor: {
    type: StyleParamType.Color,
    key: 'plan-title-color',
    getDefaultValue: wixColorParam('color-5'),
  },
  planPriceFont: {
    type: StyleParamType.Font,
    key: 'plan-price-font',
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 70 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  },
  planPriceColor: {
    type: StyleParamType.Color,
    key: 'plan-price-color',
    getDefaultValue: wixColorParam('color-5'),
  },
  planTaglineFont: {
    type: StyleParamType.Font,
    key: 'plan-tagline-font',
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 14 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  },
  planTaglineColor: {
    type: StyleParamType.Color,
    key: 'plan-tagline-color',
    getDefaultValue: wixColorParam('color-5'),
  },
  planBenefitsFont: {
    type: StyleParamType.Font,
    key: 'plan-benefits-font',
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 14 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  },
  planBenefitsColor: {
    type: StyleParamType.Color,
    key: 'plan-benefits-color',
    getDefaultValue: wixColorParam('color-5'),
  },
  planRecurringOptionsFont: {
    type: StyleParamType.Font,
    key: 'plan-recurring-options-font',
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 12 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  },
  planRecurringOptionsColor: {
    type: StyleParamType.Color,
    key: 'plan-recurring-options-color',
    getDefaultValue: wixColorParam('color-5'),
  },
  planDurationFont: {
    type: StyleParamType.Font,
    key: 'plan-duration-font',
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 12 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  },
  planDurationColor: {
    type: StyleParamType.Color,
    key: 'plan-duration-color',
    getDefaultValue: wixColorParam('color-3'),
  },
  ribbonBackgroundColor: {
    type: StyleParamType.Color,
    key: 'ribbon-bg-opacity-and-color',
    getDefaultValue: wixColorParam('color-18'),
  },
  ribbonFont: {
    type: StyleParamType.Font,
    key: 'ribbon-font',
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 14 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  },
  ribbonColor: {
    type: StyleParamType.Color,
    key: 'ribbon-color',
    getDefaultValue: wixColorParam('color-1'),
  },
  ribbonBorderWidth: {
    type: StyleParamType.Number,
    key: 'ribbon-border-width',
    getDefaultValue: () => 0,
  },
  ribbonBorderColor: {
    type: StyleParamType.Color,
    key: 'ribbon-border-color',
    getDefaultValue: wixColorParam('color-5', 0),
  },
  planTitleColorHighlighted: {
    type: StyleParamType.Color,
    key: 'plan-title-color-highlighted',
    getDefaultValue: wixColorParam('color-5'),
  },
  planPriceColorHighlighted: {
    type: StyleParamType.Color,
    key: 'plan-price-color-highlighted',
    getDefaultValue: wixColorParam('color-5'),
  },
  planRecurringOptionsColorHighlighted: {
    type: StyleParamType.Color,
    key: 'plan-recurring-options-color-highlighted',
    getDefaultValue: wixColorParam('color-5'),
  },
  planTaglineColorHighlighted: {
    type: StyleParamType.Color,
    key: 'plan-tagline-color-highlighted',
    getDefaultValue: wixColorParam('color-5'),
  },
  planDurationColorHighlighted: {
    type: StyleParamType.Color,
    key: 'plan-duration-color-highlighted',
    getDefaultValue: wixColorParam('color-3'),
  },
  planBenefitsColorHighlighted: {
    type: StyleParamType.Color,
    key: 'plan-benefits-color-highlighted',
    getDefaultValue: wixColorParam('color-5'),
  },
  buttonFont: {
    type: StyleParamType.Font,
    key: 'button-font',
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 14 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  },
  buttonFontColor: {
    type: StyleParamType.Color,
    key: 'button-font-color',
    getDefaultValue: wixColorParam('color-1'),
  },
  buttonCornerRadius: {
    type: StyleParamType.Number,
    key: 'button-corner-radius',
    getDefaultValue: () => 0,
  },
  buttonBorderWidth: {
    type: StyleParamType.Number,
    key: 'button-border-width',
    getDefaultValue: () => 1,
  },
  buttonBorderColor: {
    type: StyleParamType.Color,
    key: 'button-border-color',
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonColor: {
    type: StyleParamType.Color,
    key: 'button-color',
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonFontColorHighlighted: {
    type: StyleParamType.Color,
    key: 'button-font-color-highlighted',
    getDefaultValue: wixColorParam('color-1'),
  },
  buttonCornerRadiusHighlighted: {
    type: StyleParamType.Number,
    key: 'button-corner-radius-highlighted',
    getDefaultValue: () => 0,
  },
  buttonBorderColorHighlighted: {
    type: StyleParamType.Color,
    key: 'button-border-color-highlighted',
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonColorHighlighted: {
    type: StyleParamType.Color,
    key: 'button-color-highlighted',
    getDefaultValue: wixColorParam('color-8'),
  },

  buttonEmptyCorneredFontStyle: {
    type: StyleParamType.Font,
    key: 'empty-cornered-font-style',
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 14 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  },
  buttonEmptyCorneredFontColor: {
    type: StyleParamType.Color,
    key: 'empty-cornered-font-color',
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonEmptyCorneredBorderWidth: {
    type: StyleParamType.Number,
    key: 'empty-cornered-border-width',
    getDefaultValue: () => 1,
  },
  buttonEmptyCorneredCornerRadius: {
    type: StyleParamType.Number,
    key: 'empty-cornered-corner-radius',
    getDefaultValue: () => 0,
  },
  buttonEmptyCorneredBorderOpacityAndColor: {
    type: StyleParamType.Color,
    key: 'empty-cornered-border-opacity-and-color',
    getDefaultValue: wixColorParam('color-8'),
  },

  buttonEmptyRoundedFontStyle: {
    type: StyleParamType.Font,
    key: 'empty-rounded-font-style',
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 14 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  },
  buttonEmptyRoundedFontColor: {
    type: StyleParamType.Color,
    key: 'empty-rounded-font-color',
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonEmptyRoundedBorderWidth: {
    type: StyleParamType.Number,
    key: 'empty-rounded-border-width',
    getDefaultValue: () => 1,
  },
  buttonEmptyRoundedCornerRadius: {
    type: StyleParamType.Number,
    key: 'empty-rounded-corner-radius',
    getDefaultValue: () => 100,
  },
  buttonEmptyRoundedOpacityAndColor: {
    type: StyleParamType.Color,
    key: 'empty-rounded-border-opacity-and-color',
    getDefaultValue: wixColorParam('color-8'),
  },

  buttonFullCorneredFontStyle: {
    type: StyleParamType.Font,
    key: 'full-cornered-font-style',
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 14 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  },
  buttonFullCorneredFontColor: {
    type: StyleParamType.Color,
    key: 'full-cornered-font-color',
    getDefaultValue: wixColorParam('color-1'),
  },
  buttonFullCorneredCornerRadius: {
    type: StyleParamType.Number,
    key: 'full-cornered-corner-radius',
    getDefaultValue: () => 0,
  },
  buttonFullCorneredBgColor: {
    type: StyleParamType.Color,
    key: 'full-cornered-bg-opacity-and-color',
    getDefaultValue: wixColorParam('color-8'),
  },

  buttonFullRoundedFontStyle: {
    type: StyleParamType.Font,
    key: 'full-rounded-font-style',
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 14 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  },
  buttonFullRoundedFontColor: {
    type: StyleParamType.Color,
    key: 'full-rounded-font-color',
    getDefaultValue: wixColorParam('color-1'),
  },
  buttonFullRoundedCornerRadius: {
    type: StyleParamType.Number,
    key: 'full-rounded-corner-radius',
    getDefaultValue: () => 100,
  },
  buttonFullRoundedBgColor: {
    type: StyleParamType.Color,
    key: 'full-rounded-bg-opacity-and-color',
    getDefaultValue: wixColorParam('color-8'),
  },
  showPageTitle: {
    type: StyleParamType.Boolean,
    key: 'show-page-title',
    getDefaultValue: () => true,
  },
});
