import React, { useState } from 'react';
import Collapsible from 'react-collapsible';

import { st, classes } from './Footer.st.css';
import { ArrowDown } from '../ArrowDown';
import { useSettings } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import settingsParams from '../../settingsParams';

interface Props {
  highlighted: boolean;
  anyBenefits?: boolean;
  isMobile?: boolean;
  benefits?: String[];
}

export const Footer: React.FC<Props> = ({ isMobile, benefits, anyBenefits, highlighted }) => {
  const [expanded, setExpanded] = useState<boolean>(!isMobile);
  const settings = useSettings();
  const showPlanBenefits = settings.get(settingsParams.showBenefits);
  const showDividers = settings.get(settingsParams.showBenefitsDividers) as boolean;

  if (!showPlanBenefits || !anyBenefits || !benefits) {
    return null;
  }

  const renderBenefits = () => {
    return (
      <ul className={st(classes.benefits, { showDividers, highlighted })}>
        {benefits.map((benefit, i) => (
          <li key={i}>{benefit}</li>
        ))}
      </ul>
    );
  };

  if (!isMobile) {
    return renderBenefits();
  }

  return (
    <div onClick={() => setExpanded(!expanded)} style={{ flex: 1 }}>
      <Collapsible trigger={''} open={expanded} easing="ease-out">
        {renderBenefits()}
      </Collapsible>
      {<ArrowDown className={st(classes.arrow, { expanded, highlighted })} />}
    </div>
  );
};
