import { SettingsParamType, createSettingsParams } from 'yoshi-flow-editor-runtime/tpa-settings';

export enum TextAlignment {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export enum ButtonStyle {
  EMPTY_ROUNDED = '0',
  EMPTY_CORNERED = '1',
  FULL_ROUNDED = '2',
  FULL_CORNERED = '3',
}

export type ISettingsParams = {
  textAlignment: TextAlignment;
  buttonStyle: ButtonStyle;
  buttonStyleHighlighted: ButtonStyle;
  pageHeaderText: SettingsParamType.String;
  buttonText: SettingsParamType.String;
  freeTrialButtonText: SettingsParamType.String;
  ribbonText: SettingsParamType.String;
  disclaimerText: SettingsParamType.String;
  showPageTitle: SettingsParamType.Boolean;
  showPlanName: SettingsParamType.Boolean;
  showTagline: SettingsParamType.Boolean;
  showBenefits: SettingsParamType.Boolean;
  showBenefitsDividers: SettingsParamType.Boolean;
  showHighlightedPlan: SettingsParamType.Boolean;
  showDisclaimer: SettingsParamType.Boolean;
  showRibbon: SettingsParamType.Boolean;
  hiddenPlans: SettingsParamType.String;
  planOrder: SettingsParamType.String;
  highlightedPlan: SettingsParamType.String;
};

export default createSettingsParams<ISettingsParams>({
  textAlignment: {
    key: 'text-alignment',
    getDefaultValue: () => TextAlignment.CENTER,
  },
  buttonStyle: {
    key: 'button-style',
    getDefaultValue: () => ButtonStyle.FULL_CORNERED,
  },
  buttonStyleHighlighted: {
    key: 'button-style-highlighted',
    getDefaultValue: () => ButtonStyle.FULL_CORNERED,
  },
  pageHeaderText: {
    key: 'page-title-text',
    getDefaultValue: ({ t, getSettingParamValue }) => {
      const textData = getSettingParamValue({
        key: 'pp-text-tab-data',
        inheritFromAppScope: true,
        getDefaultValue: () => undefined,
      });
      return textData?.['page-title-text'] || t('pp.defaults.pageTitle');
    },
  },
  buttonText: {
    key: 'button-text',
    getDefaultValue: ({ t, getSettingParamValue }) => {
      const textData = getSettingParamValue({
        key: 'pp-text-tab-data',
        inheritFromAppScope: true,
        getDefaultValue: () => undefined,
      });
      return textData?.['button-text'] || t('pp.defaults.button');
    },
  },
  freeTrialButtonText: {
    key: 'free-trial-button-text',
    getDefaultValue: ({ t, getSettingParamValue }) => {
      const textData = getSettingParamValue({
        key: 'pp-text-tab-data',
        inheritFromAppScope: true,
        getDefaultValue: () => undefined,
      });
      return textData?.['free-trial-button-text'] || t('pp.defaults.freeTrialButton');
    },
  },
  ribbonText: {
    key: 'ribbon-text',
    getDefaultValue: ({ t, getSettingParamValue }) => {
      const textData = getSettingParamValue({
        key: 'pp-text-tab-data',
        inheritFromAppScope: true,
        getDefaultValue: () => undefined,
      });
      return textData?.['ribbon-text'] || t('pp.defaults.ribbon');
    },
  },
  disclaimerText: {
    key: 'disclaimer-text',
    getDefaultValue: ({ t, getSettingParamValue }) => {
      const textData = getSettingParamValue({
        key: 'pp-text-tab-data',
        inheritFromAppScope: true,
        getDefaultValue: () => undefined,
      });
      return textData?.['disclaimer-text'] || '';
    },
  },
  showPageTitle: {
    key: 'show-page-title',
    getDefaultValue: () => true,
  },
  showPlanName: {
    key: 'show-plan-name',
    getDefaultValue: () => true,
  },
  showTagline: {
    key: 'show-plan-tagline',
    getDefaultValue: () => true,
  },
  showBenefits: {
    key: 'show-plan-benefits',
    getDefaultValue: () => true,
  },
  showBenefitsDividers: {
    key: 'show-benefits-dividers',
    getDefaultValue: () => true,
  },
  showHighlightedPlan: {
    key: 'show-highlighted-plan',
    getDefaultValue: () => true,
  },
  showDisclaimer: {
    key: 'show-disclaimer',
    getDefaultValue: () => false,
  },
  showRibbon: {
    key: 'show-ribbon',
    getDefaultValue: () => true,
  },
  hiddenPlans: {
    key: 'hidden-plans',
    getDefaultValue: () => '',
  },
  planOrder: {
    key: 'plan-order',
    getDefaultValue: () => '',
  },
  highlightedPlan: {
    key: 'highlighted-plan',
    getDefaultValue: () => '',
  },
});
