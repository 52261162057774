import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';

import { HIGHGLIGHTED_MOCK_PLAN_ID } from '../../../../fixtures';

interface HightlightOptions {
  showHighlightedPlan: boolean;
  highlightedPlanId: string;
  showDemoHighlight?: boolean;
}

export function getHighlightedPlanId(plans: PublicPlan[], options: HightlightOptions): string {
  const { showHighlightedPlan, highlightedPlanId, showDemoHighlight } = options;
  const primaryPlanId = plans.find((plan) => plan.primary)?.id;

  if (showHighlightedPlan && highlightedPlanId) {
    return highlightedPlanId;
  }

  if (showHighlightedPlan && !highlightedPlanId && primaryPlanId) {
    return primaryPlanId;
  }

  return (
    plans.find((p) => p.id === HIGHGLIGHTED_MOCK_PLAN_ID)?.id ||
    (showDemoHighlight && (plans[1]?.id || plans[0]?.id)) ||
    ''
  );
}
