import React from 'react';
import { useTranslation } from 'yoshi-flow-editor-runtime';
import { useSettings } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import settingsParams from '../../settingsParams';

import { classes } from './Ribbon.st.css';

interface Props {
  highlighted: boolean;
}

export const Ribbon: React.FC<Props> = ({ highlighted }) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const showRibbon = settings.get(settingsParams.showRibbon);

  if (!highlighted || !showRibbon) {
    return null;
  }

  return (
    <div data-hook="ribbon" className={classes.ribbon}>
      {settings.get(settingsParams.ribbonText)}
    </div>
  );
};
